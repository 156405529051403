<template>
    <div class="container-xl" v-if="!$store.getters.isForbidden">
      <div class="card">
        <div class="card-header border-0 justify-content-between">
          <div class="card-title">
            Activities Log
          </div>
          <div class="flex flex-row mb-2 pr-2 float-right">
          </div>
        </div>
        <div class="card-table table-responsive">
          <table class="table table-vcenter">
            <thead>
              <tr>
                <th width="30%">ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Event</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-loading="isLoading">
              <tr v-for="(item, i) in list" :key="i">
                <td class="text-nowrap text-muted">{{ item.id }}</td>
                <td class="text-nowrap text-muted">{{ item.user_data.name }}</td>
                <td class="text-nowrap text-muted">{{ item.user_data.email }}</td>
                <td class="text-nowrap text-muted">{{ formatAction(item.action) }}</td>
                <td class="text-nowrap text-muted">{{ item.user_data.status }}</td>
                <td>
                  <button class="btn btn-secondary btn-sm dropdown-toggle" data-toggle="dropdown">Actions</button>
                  <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow p-2" style="width: 200px">
                    <a class="dropdown-item btn-sm cursor-pointer" @click="doShowModal(item.id)"><font-awesome-icon class="text-primary" icon="pen"/>&nbsp; View Detail</a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <el-empty description="No data found" v-if="total_rows == 0" :image="emptyStateImage"></el-empty>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <small>Showing {{ showingFrom }} to {{ showingUntil }} of {{ total_rows }} entries</small>
          <div class="inline-table">
            <b-pagination
              class="table-cell"
              @change="pageChangeHandler"
              :per-page="per_page"
              :limit="5"
              :total-rows="total_rows"
              v-model="page"
            />
          </div>

          <!-- {{ list }} -->
        </div>
        <b-modal v-model="showDetail" :title="modalTitle" hide-footer>
          <b-form @reset="closeModalCreate">
            <b-form-group label="Request ID" :disabled="true">
              <b-input v-model="selectedItem.request_id" />
            </b-form-group>
            <b-form-group label="Method" :disabled="true">
              <b-input v-model="selectedItem.method" />
            </b-form-group>
            <b-form-group label="End Point" :disabled="true">
              <b-input v-model="selectedItem.endpoint" />
            </b-form-group>
            <div class="d-flex flex-row float-right">
              <el-button @click="closeModalCreate" size="small" class="mr-2">Cancel</el-button>
            </div>
          </b-form>
        </b-modal>
      </div>
    </div>
</template>
<script>
import moment from 'moment';
import useVuelidate from '@vuelidate/core';
import emptyStateImage from '@/assets/images/empty-state.png';
import { GET_ACTIVITIES } from '../../store/modules/activitys';

export default {
  name: 'Activities',
  metaInfo: {
    title: 'Activities',
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      selectedItem: {
        request_id: '',
        method: '',
        endpoint: '',
      },
      emptyStateImage,
      per_page: 20,
      total_rows: 0,
      page: 1,
      is_private: 1,
      list: [],
      isLoading: true,
      loader: null,
      search_keyword: '',
      search_by: '',
      modalTitle: 'View Detail',
      showDetail: false,
      moment,
      disabled: false,
    };
  },
  computed: {
    showingFrom() {
      return this.total_rows ? ((this.page - 1) * this.per_page) + 1 : 0;
    },
    showingUntil() {
      if (this.total_rows) {
        if (this.list.length < this.per_page) {
          return this.total_rows;
        }
        return this.page * this.per_page;
      }
      return 0;
    },

    currentRole() {
      const role = this.$store.getters.current_role;
      console.log('Current Role:', role);
      return role;
    },
  },
  async mounted() {
    const loader = this.$loading.show();
    await this.getList();
    loader.hide();
  },
  methods: {
    pageChangeHandler(page) {
      this.page = page;
      this.getList();
    },
    searchHandler() {
      this.page = 1;
      this.getList();
    },
    async getList() {
      this.isLoading = true;
      await this.$store.dispatch(GET_ACTIVITIES, {
        page: this.page,
        per_page: this.per_page,
        search_by: this.search_by,
        // search_keyword: this.search_keyword,
        is_private: this.is_private
      }).catch(() => {});
      const { rows, count } = this.$store.getters.activities;
      this.total_rows = count || 0;
      this.list = rows;
      this.isLoading = false;
    },
    closeModalCreate() {
      this.showDetail = false;
    },
    doShowModal(id) {
      const selectedItems = this.list.find((item) => item.id === id);
      if (selectedItems) {
        this.selectedItem = selectedItems;
        this.showDetail = true;
      } else {
        console.error("Item not found with id:", id);
      }
    },
    formatAction(action) {
      if (!action) return ''; 
      return action
        .replace(/_/g, ' ') 
        .toLowerCase() 
        .replace(/\b\w/g, (char) => char.toUpperCase()); 
    },
  },
};
</script>
